@import "../../styles/mixin.scss";

.heroContainer {
  height: 60vh;
  display: flex;

  .imageContainer {
    position: relative;
    width: 50%;
  }

  .imageContainer::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url("../../images/hero-image.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    filter: brightness(80%);
  }

  .introContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 5rem;
    width: 50%;

    @include for-phone-only {
      padding: 0 2rem;
    }

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #f6f7eb;
      background-color: #393e41;
      padding: 1rem;
      text-decoration: none;
      align-self: center;
      margin: 0.5rem 0;
      border-radius: 0.5rem;
      text-align: center;

      svg {
        margin-right: 0.5rem;
      }
    }

    .visitorCount {
      align-self: center;
    }
  }
}
