@import url("https://fonts.googleapis.com/css2?family=Instrument+Sans:ital,wght@0,400;0,500;0,600;0,700;1,400&display=swap");

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
  font: inherit;
}

html {
  font-size: 62.5%;
  background-color: #f6f7eb;
}

body {
  height: 100vh;
  font-size: 1.4rem;
  font-family: Instrument Sans;
  color: #393e41;
}

img,
picture,
svg,
video {
  display: block;
  max-width: 100%;
}

input,
select,
textarea,
button {
  font: inherit;
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2.5rem;
  font-weight: 700;
  color: #e94f37;
}

h3 {
  font-size: 2rem;
  font-weight: 600;
}

p,
li {
  font-size: 1.6rem;
}
