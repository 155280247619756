.footerContainer {
  background-color: #393e41;
  color: #f6f7eb;
  padding: 1.5rem;
  display: flex;
  align-items: center;

  .visitorCount,
  .copyWrite,
  .contactIcons {
    flex: 1;
    display: flex;
    justify-content: center;
  }

  .visitorCount {
    margin-right: auto;
    justify-content: flex-start;

    img {
      width: 5rem;
      height: 5rem;
    }
  }

  .contactIcons {
    margin-left: auto;
    justify-content: flex-end;

    .icon {
      font-size: xx-large;
      color: #f6f7eb;
      margin-right: 1.5rem;
    }
  }
}
