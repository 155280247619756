.headerContainer {
  background-color: #f6f7eb;
  padding: 0.5rem;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  opacity: 1;
  transition: opacity 5s linear;
  display: flex;

  &.hidden {
    position: relative;
    display: none;
    opacity: 0;
  }

  .logo {
    width: 5rem;
    height: 5rem;
  }

  .menu {
    display: flex;
    justify-content: space-evenly;
    width: 50%;

    a:hover {
      color: #814c4c;
    }
  }

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #f6f7eb;
    background-color: #393e41;
    padding: 1rem;
    text-decoration: none;
    align-self: center;
    margin: 0.5rem 0;
    border-radius: 0.5rem;
    text-align: center;

    svg {
      margin-right: 0.5rem;
    }
  }
}
