@import "../../styles/mixin.scss";

.content {
  margin: 2rem 10rem;

  @include for-phone-only {
    margin: 2rem 5rem;
  }
}

.contentContainer {
  margin: 2rem 0;

  &_center,
  .contentHeader {
    text-align: center;
  }
}

.skills {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: 0 auto;

  li {
    list-style: none;
  }

  @include for-phone-only {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  @include for-tablet-portrait-up {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  div {
    @include for-phone-only {
      margin-bottom: 1rem;
    }

    @include for-tablet-portrait-up {
      margin-bottom: 1rem;
    }
  }
}

.experienceHeader {
  display: flex;
  justify-content: space-between;
  margin: 1.5rem 0 0.5rem 0;

  @include for-phone-only {
    flex-direction: column;
  }

  .jobTitle {
    font-style: italic;
  }
}

.education {
  margin: 0.5rem 0;
}

.certificates {
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: -webkit-center;

  @include for-phone-only {
    flex-direction: column;
  }

  div {
    filter: grayscale(90%);
    transition: 0.5s ease-in-out;

    &:hover {
      filter: grayscale(0%);
    }
  }

  img {
    width: 20rem;
    height: 20rem;
  }
}
